import axios from "axios";
import { AppUtils } from "../utils/appUtils";
import { IS_SESSION_EXPIRED } from "../utils/constants";
import { SessionStorage } from "../utils/storage/sessionStorage";

export const LOCAL_CONSTANTS = {
  BASE_URL: "http://localhost:8089/api/"
};

export const DEV_CONSTANTS = {
  BASE_URL: "https://janaawaaj.asktrek.com/api/"
};

export const PROD_CONSTANTS = {
  BASE_URL: "https://janaawaaj.samyantra.org/api/"
};

const BASE_URL = PROD_CONSTANTS.BASE_URL;

export const API_URL = {
  login: BASE_URL + "login",
  signup: BASE_URL + "register",
  user: BASE_URL + "user",
  municipality: BASE_URL + "municipality",
  logOut: BASE_URL + "logout",
  resetPassword: BASE_URL + "public/reset-password",
  forgetPassword: BASE_URL + "public/forget-password",
  contactUs: BASE_URL + "public/contact-us",
  changePassword: BASE_URL + "change-password",
  palikaUsers: BASE_URL + "user/palika-users",
  complaintDetail: BASE_URL + "complaint-detail",
  applicationSetting: BASE_URL + "application-setting",
  complaintStatus: BASE_URL + "complaint-status",
  profile: BASE_URL + "user/update-profile",
  setting: BASE_URL + "user/setting",
  department: BASE_URL + "user/department",
};

const JA = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true
});

JA.interceptors.request.use(
  config => {
    document.body["children"].root.classList.add('body-loader');
    return config;
  },
  error => Promise.reject(error)
);

JA.interceptors.response.use(
  function (response) {
    document.body["children"].root.classList.remove('body-loader');
    return response;
  },
  function (error) {
    document.body["children"].root.classList.remove('body-loader');
    if (error.response.status === 401 || error.response.status === 403) {
      AppUtils.removeUserRef();
      SessionStorage.setItem(IS_SESSION_EXPIRED, "true");
      window.location.href = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

export default JA;
